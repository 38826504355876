import ComingSoon from "../components/ComingSoon";
import Page from "../components/temp-component/termsAndConditions/DeafultPage";
// import Account from "../components/account/Account";

export const RoutesArray = {
    "/": <ComingSoon/>,
    "/terms-and-conditions": <Page/>,
    "/privacy-policy": <Page/>,
    "/refund-policy": <Page/>,
    "/user-account": <Page/>
}
