import React from "react";
import styles from "./Text.module.css";

function Text({ text }) {
  return (
    <div className={styles.text_wrapper}>
     <div dangerouslySetInnerHTML={{__html:text}} />
    </div>
  );
}

export default Text;
