import React from "react";
import styles from "./Footer.module.css";
import {Link} from "react-router-dom";

function Footer() {
    return (
        <div className={styles.footer}>
            <div className={styles.brand}>
                <h3>saveit.</h3>
                <p>
                    Want to give something to someone but you are confused?, just give him
                    a gift card and let him decide
                </p>
                <h3>©2023 saveit. All right reserved</h3>
            </div>

            <div className={styles.footer_navigation}>
                <div className={styles.footer_section}>
                    <h2>About</h2>
                    <ul>
                        <li>Company</li>
                        <li>Our Service</li>
                        <li>Testimonials</li>
                        <li>Featured</li>
                    </ul>
                </div>

                <div className={styles.footer_section}>
                    <h2>Help</h2>
                    <ul>
                        <li>Contact Us</li>
                        <li>Our Service</li>
                        <li>FAQ</li>
                        <li>Term of service</li>
                        <li>Customer Service</li>
                    </ul>
                </div>

                <div className={styles.footer_section}>
                    <h2>Legal</h2>
                    <ul>
                        <li>
                            <Link to="/privacy-policy" className={styles.link_}>
                                Privacy Policy
                            </Link>
                        </li>
                        <li>
                            <Link to="/terms-and-conditions" className={styles.link_}>
                                Terms & Conditions
                            </Link>
                        </li>
                        <li>Return Policy</li>
                        <li>
                            <Link to="/refund-policy" className={styles.link_}>
                                Refund /Cancellation policy
                            </Link>
                        </li>
                    </ul>
                </div>

                <div className={styles.footer_section}>
                    <h2>Social Media</h2>
                    <ul>
                        <li>Facebook</li>
                        <li>Twitter</li>
                        <li>Instagram</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Footer;
