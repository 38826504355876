import styles from "./ComingSoon.module.css";
import giftBox from "../assets/static images.svg";
import floatingBox1 from "../assets/floating box 1.svg";
import floatingBox2 from "../assets/floating box 2.svg";
import floatingBox3 from "../assets/floating box 3.svg";
import spiral from "../assets/spiral.svg";
import {toast} from "react-toastify";
import savitLogo from "../assets/saveit-logo.png";
import {Link} from "react-router-dom";

const validRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const ComingSoon = () => {
    const handleClick = async () => {
        let email = document.getElementById("email").value;
        if (!validRegex.test(email)) {
            return toast.error("The " + email + " is not a valid email address!");
        }
        const response = await fetch(`https://api.saveit.in/subscribe`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({email}),
        });
        const parseResposen = await response.json();
        if (!parseResposen.success) {
            return toast.error(parseResposen.message);
        }
        email = "";
        return toast.success(parseResposen.message);
    };
    return (
        <>
            <div className={styles.container}>
                <div className={styles.topBar}>
                    <Link to="/">
                        <img className={styles.brand_logo} src={savitLogo} alt={"Brand Logo"}/>
                    </Link>
                </div>
                <div className={styles.box_img}>
                    <div></div>
                    <img
                        className={styles.floatingBox1}
                        src={floatingBox1}
                        alt="gift box"
                    />
                    <img
                        className={styles.floatingBox2}
                        src={floatingBox2}
                        alt="gift box"
                    />
                    <img
                        className={styles.floatingBox3}
                        src={floatingBox3}
                        alt="gift box"
                    />
                    <img className={styles.box_img_cool} src={giftBox} alt="gift box"/>
                </div>
                <div className={styles._flex}>
                    <div></div>
                    <div className={styles.hero}>
                        <div>
                            <div>
                                <h1>Coming Soon</h1>
                                <p>
                                    Get ready to unlock a world of savings at your fingertips.{" "}
                                    <br/>
                                    The future of gifting and everyday deals is just a touch away.
                                </p>
                                <p>
                                    <strong>Are you in?</strong> Subscribe now to be the first to
                                    know.
                                </p>
                            </div>
                            <div className={styles.hero_input}>
                                <input
                                    type="text"
                                    placeholder="Enter your mail address"
                                    name="email"
                                    id="email"
                                />
                            </div>
                        </div>
                        <div className={styles?.hero_btn}>
                            <button onClick={handleClick}>Subscribe</button>
                        </div>
                    </div>
                </div>
                <div>
                    <img className={styles.spiral_top} src={spiral} alt="Spiral "/>
                </div>
                <div>
                    <img className={styles.spiral_bottom} src={spiral} alt="Spiral "/>
                </div>
            </div>
            <div className={styles.bottom_links_wrapper}>
                {/* Use Link component for navigation */}
                <Link to="/privacy-policy" className={styles.link}>
                    Privacy Policy
                </Link>
                <Link to="/terms-and-conditions" className={styles.link}>
                    Terms & Conditions
                </Link>
                <Link to="/refund-policy" className={styles.link}>
                    Refund Policy
                </Link>
                {/* Add a download link or button */}
                <a href="./Saveit-apk-download-android.apk" target={"_blank"} className={styles.link}>
                    Download the App
                </a>
                <a href="./user-account"  className={styles.link}>
                    Account
                </a>
            </div>
        </>
    );
};

export default ComingSoon;
