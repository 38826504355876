import { createBrowserRouter } from "react-router-dom";
import ComingSoon from "../components/ComingSoon";
import { RoutesArray } from "./web";
const AppRoutes = Object.keys(RoutesArray).map((_key) => {
  return {
    path: _key,
    element: RoutesArray[_key],
  };
});
AppRoutes.push({
  path: "*",
  element: <ComingSoon />,
});
export const router = createBrowserRouter(AppRoutes);
