import styles from "./TopBar.module.css"
import savitLogo from "../../../../../assets/saveit-logo.png";
import {Link} from "react-router-dom";

const TopBar = () => {
    return (
        <div className={styles.topBar}>
            <Link to="/">
                <img className={styles.brand_logo} src={savitLogo} alt={"Brand Logo"}/>
            </Link>
        </div>
    )
}
export default TopBar;

