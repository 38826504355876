import DefaultLayout from "../layout";
import Text from "./components/text/Text";
import { Data } from "../../../data";
import { useLocation } from "react-router-dom";

const Page = () => {
  const { pathname } = useLocation();
  return (
    <DefaultLayout>
      <Text text={Data[pathname]} />
    </DefaultLayout>
  );
};

export default Page;
