import styles from "./Hero.module.css";
import heroImg from "../../../../../assets/temp-component-assets/Policy.svg";
import {useLocation} from "react-router-dom";

const Hero = () => {
    const {pathname} = useLocation();
    const pageName = pathname.split("/").pop().replace(/-/g, " ").replace(/^\w/, c => c.toUpperCase());

    return (
        <div className={styles.hero}>
            <div>
                <h1>
                    saveit. Enterprises (India) <br/> {pageName}
                </h1>
                <h2>We value your privacy</h2>
            </div>
            <img src={heroImg} className={styles.hero_img} alt="hero img"/>
        </div>
    );
};

export default Hero;
