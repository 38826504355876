import P from "../assets/temp-component-assets/Policy.svg";

export const Data = {
    "/terms-and-conditions": `
    <h1>Terms and Conditions</h1>
    
        <h2>Definitions:</h2>
        <p>
            "Client," "You," and "Your" refer to you, the person accessing this website and accepting Saveit India's terms and conditions.
        </p>
        <p>
            "The Company," "Ourselves," "We," and "Us" refer to Saveit India.
        </p>
        <p>
            "Party," "Parties," or "Us" refer to both the Client and Saveit India.
        </p>
    
        <h2>Privacy Statement:</h2>
        <p>
            We are committed to protecting your privacy. Authorized employees within the company on a need-to-know basis only use any information collected from individual customers.
        </p>
        <p>
            Confidentiality: Any information concerning the Client and their respective Client Records may be passed to third parties. However, Client records are regarded as confidential and will not be divulged to any third party, other than our manufacturer/supplier(s) and if legally required to do so to the appropriate authorities.
        </p>
    
        <h2>Disclaimer:</h2>
        <p>
            Exclusions and Limitations: The information on this website is provided on an "as is" basis. Saveit India excludes all representations and warranties relating to this website and its contents. Saveit India excludes all liability for damages arising out of or in connection with your use of this website, except for liability for death or personal injury caused by its negligence.
        </p>
    
        <h2>Payment:</h2>
        <p>
            Payment to Saveit India will be processed by the specified payment gateway, offering various payment options including Credit Cards, Debit Cards, and Net banking.
        </p>
    
        <h2>Availability:</h2>
        <p>
            Redistribution or republication of any part of this site or its content is prohibited without the express written consent of Saveit India. The Company does not warrant that the service from this site will be uninterrupted, timely, or error-free.
        </p>
    
        <h2>Links to and from this website:</h2>
        <p>
            You may not create a link to any page of this website without Saveit India's prior written consent. Saveit India is not responsible for the content of external websites linked to from this site.
        </p>
    
        <h2>Copyright Notice:</h2>
        <p>
            Copyright and other relevant intellectual property rights exist on all text relating to Saveit India's services and the full content of this website.
        </p>
    
        <h2>Communication:</h2>
        <p>
            Contact information for Saveit India can be found on our website or via Company literature.
        </p>
    
        <h2>Governing Law:</h2>
        <p>
            The laws of India govern these terms and conditions. By accessing this website, you consent to these terms and conditions and to the exclusive jurisdiction of the Indian courts.
        </p>
    
        <h2>Changes and Notifications:</h2>
        <p>
            Saveit India reserves the right to change these conditions from time to time. Changes will be announced on key pages of our site. You are advised to re-read this statement regularly.
        </p>
    
        <h2>Agreement:</h2>
        <p>
            These terms and conditions form part of the Agreement between the Client and Saveit India. Your accessing of this website indicates your understanding, agreement to, and acceptance of the Disclaimer Notice and the full Terms and Conditions contained herein. Your statutory Consumer Rights are unaffected.
        </p>
    
        <p>
            <strong>I, by using the website, agree and accept the following:</strong>
        </p>
        <ul>
            <li>Terms & Conditions</li>
            <li>Privacy policy</li>
            <li>Cancellation & Refund policy</li>
        </ul>
        <p>
            Please cease using the site if you do not agree to the Terms & Conditions.
        </p>
    
        <h2>Payment Policy:</h2>
        <p>
            Payments for the Gift Vouchers (GV) to Saveit India must be made upfront. The full value of the GV must be credited before it is sent to the recipient.
        </p>
    
    `,
    "/privacy-policy": `
    <h1>Privacy Policy</h1>

    <p>
        This Privacy Policy describes how Saveit India Pvt Ltd manages personal information and respects your privacy. This policy may be amended from time to time.
    </p>
    <p>
        We value the trust you place in us. That’s why we insist upon the highest standards for secure transactions and customer information privacy. Please read the following statement to learn about our information gathering and dissemination practices.
    </p>
    <p>
        Our privacy policy is subject to change at any time without notice. To make sure you are aware of any changes, please review this policy periodically.
    </p>
    <p>
        By visiting this Website, you agree to be bound by the terms and conditions of this Privacy Policy. If you do not agree, please do not use or access our Website.
    </p>
    <p>
        By mere use of the Website, you expressly consent to our use and disclosure of personal information provided by you in accordance with this Privacy Policy. This Privacy Policy is incorporated into and subject to the Terms of Use.
    </p>
    <p>
        We, Saveit India Pvt Ltd, incorporated under the Companies Act, 2013 (hereinafter referred to as “Company”), the creator of this Privacy Policy, ensure our firm commitment to your privacy vis-à-vis the protection of your priceless information. In order to endow you with our uninterrupted use of services, we may collect and, in some circumstances, disclose information about you. To enhance better protection of your privacy, we provide this notice explaining our information practices and the choices you can make about the way your information is collected and used.
    </p>
    <p>
        All visitors to <a href="https://saveit.in/">https://saveit.in/</a> (Website) are advised to read and understand our Privacy Policy carefully, as by accessing the Website you agree to be bound by the terms and conditions of the Privacy Policy and consent to the collection, storage, and use of information relating to you as provided herein.
    </p>
    <p>
        If you do not agree with the terms and conditions of our Privacy Policy, including in relation to the manner of collection or use of your information, please do not use or access the Website.
    </p>
    <p>
        Saveit India Pvt Ltd may revise this Privacy Policy. We will give you notice by posting the revised Privacy Policy on our Website and updating the “Last Updated” date at the top of this Privacy Policy; we may also notify you in other ways, such as through the contact information you have provided. Any changes to this Privacy Policy will be effective immediately unless otherwise stated. By continuing to use our Website, you agree to the revised Privacy Policy.
    </p>

    <h2>Collection of Personally Identifiable Information and other Information</h2>

    <p>
        When you use our Website, we collect and store your personal information provided by you from time to time. Our primary goal in doing so is to provide you a safe, efficient, smooth, and customized experience. This allows us to provide services and features that most likely meet your needs and to customize our Website to make your experience safer and easier. More importantly, while doing so we collect personal information from you that we consider necessary for achieving this purpose.
    </p>
    <p>
        In general, you can browse the Website without telling us who you are or revealing any personal information about yourself. Once you give us your personal information, you are not anonymous to us. Where possible, we indicate which fields are required and which fields are optional. You always have the option to not provide information by choosing not to use a particular service or feature on the Website. We may automatically track certain information about you based upon your behavior on our Website. We use this information to do internal research on our users’ demographics, interests, and behavior to better understand, protect and serve our users. This information is compiled and analyzed on an aggregated basis. This information may include the URL that you just came from (whether this URL is on our Website or not), which URL you next go to (whether this URL is on our Website or not), your computer browser information, and your IP address.
    </p>
    <p>
        We use data collection devices such as “cookies” on certain pages of the Website to help analyze our web page flow, measure promotional effectiveness, and promote trust and safety. “Cookies” are small files placed on your hard drive that assist us in providing our services. We offer certain features that are only available through the use of a “cookie”. We also use cookies to allow you to enter your password less frequently during a session. Cookies can also help us provide information that is targeted to your interests. Most cookies are “session cookies,” meaning that they are automatically deleted from your hard drive at the end of a session. You are always free to decline our cookies if your browser permits, although in that case you may not be able to use certain features on the Website and you may be required to re-enter your password more frequently during a session.
    </p>
    <p>
        Additionally, you may encounter “cookies” or other similar devices on certain pages of the Website that are placed by third parties. We do not control the use of cookies by third parties.
    </p>
    <p>
        If you choose to buy on the Website, we collect information about your buying behavior, preferences, and other such information that you choose to provide.
    </p>
    <p>
        If you transact with us, we collect some additional information, such as a billing address, a credit/debit card number, and a credit/debit card expiration date and/or other payment instrument details and tracking information from cheques or money orders.
    </p>
    <p>
        If you choose to post messages on our message boards, chat rooms, or other message areas or leave feedback or if you use voice commands to shop on the Website, we will collect that information you provide to us. We retain this information as necessary to resolve disputes, provide customer support and troubleshoot problems as permitted by law.
    </p>
    <p>
        If you send us personal correspondence, such as emails or letters, or if other users or third parties send us correspondence about your activities or postings on the Website, we may collect such information into a file specific to you.
    </p>
    <p>
        We collect personally identifiable information (email address, name, phone number, credit card/debit card/other payment instrument details, etc.) from you when you set
        <h2>Use of Demographic/Profile Data/Your Information</h2>

        <p>
            We use personal information to provide the services you request. To the extent we use your personal information to market to you, we will provide you the ability to opt-out of such uses. We use your personal information to assist sellers in handling and fulfilling orders, enhancing customer experience, resolving disputes, troubleshooting problems, helping promote a safe service, collecting money, measuring consumer interest in our products and services, informing you about online and offline offers, products, services, and updates, customizing and enhancing your experience, detecting and protecting us against error, fraud, and other criminal activity, enforcing our terms and conditions, and as otherwise described to you at the time of collection.
        </p>
    
        <p>
            With your consent, we will have access to your SMS, contacts in your directory, location and device information, and we may request you to provide your PAN and Know-Your-Customer (KYC) details to check your eligibility for certain products/services including but not limited to credit and payment products, etc., to enhance your experience on the platform and provide you access to the services being offered by us, our affiliates or lending partners. Access, storage, and use of this data will be in consonance with applicable laws. You understand that your access to these products/services may be affected in the event consent is withdrawn.
        </p>
    
        <p>
            In our efforts to continually improve our product and service offerings,
        </p>

    `,
    "/refund-policy": `
    <h1>Refund Policy</h1>
    <p>
      Thank you for choosing Saveit India Pvt Ltd. Your satisfaction is paramount to us. We've crafted a comprehensive refund policy to ensure transparency and fairness in every transaction. Please take a moment to review the details below:
    </p>

    <h2>1. Refund Eligibility:</h2>
    <ul>
      <li>We offer refunds exclusively for unused gift vouchers purchased directly from Saveit India Pvt Ltd.</li>
      <li>To be eligible for a refund, the gift voucher must remain unused and in its original, undamaged condition.</li>
      <li>Partially redeemed vouchers are not eligible for refunds.</li>
    </ul>

    <h2>2. Refund Process:</h2>
    <ul>
      <li>To initiate a refund, please reach out to our dedicated customer service team within [number of days] days from the date of purchase.</li>
      <li>For processing, we require proof of purchase.</li>
      <li>Once eligibility is confirmed, refunds will be credited back to the original payment method within [number of days] days.</li>
    </ul>

    <h2>3. Non-Refundable Items:</h2>
    <ul>
      <li>Customized or personalized gift vouchers are considered final sale and are non-refundable.</li>
      <li>Service charges or convenience fees associated with the purchase are non-refundable.</li>
    </ul>

    <h2>4. Cancellation Policy:</h2>
    <ul>
      <li>Orders may be canceled within [number of hours/days] of purchase if the gift vouchers have not yet been redeemed.</li>
      <li>A cancellation fee may apply, which will be disclosed at the time of cancellation.</li>
    </ul>

    <h2>5. Exceptions:</h2>
    <ul>
      <li>Refunds are possible in cases of fraudulent transactions or unauthorized voucher usage, subject to thorough investigation.</li>
    </ul>

    <h2>6. Contact Information:</h2>
    <ul>
      <li>For refund inquiries or assistance, please don't hesitate to contact our customer service team at [contact email/phone number].</li>
      <li>Our dedicated representatives are available [hours of operation] to address any concerns you may have regarding refunds or our policies.</li>
    </ul>

    <h2>7. Policy Changes:</h2>
    <ul>
      <li>Saveit India Pvt Ltd reserves the right to update or modify this refund policy without prior notice. Any revisions will be promptly communicated through our website.</li>
    </ul>

    <p>
      We appreciate your trust in Saveit India Pvt Ltd. Should you require further clarification or have any questions regarding our refund policy, please feel free to contact us. Thank you for choosing us as your gifting partner. We are committed to providing you with exceptional service and support throughout your journey with us.
    </p>
  `,
    "/user-account": `
    <h1>Delete Your Saveit Account From App</h1>
    <p>Please note that deleting your account will permanently remove all your data and settings associated with your account. </p>
    <p>This action cannot be undone, so please make sure you want to proceed. If you're sure, click the 'Delete Account' button below. If you have any questions or concerns, please contact our support team for assistance.</p>
   

    <h2>Steps To Permanently Delete Your Saveit Account</h2>
    <div>
      <div>
      <p>1. Open the app and proceed to the Profile tab.</p>
      <img src=https://saveit-asset-bucket.s3.us-east-2.amazonaws.com/comingSoon/Home.png alt="Saveit Home Screen Image" width="250px" height="500px"> 
      </div>
      <p>2. Within the Profile tab, locate and select the "My Profile" menu.</p>
      <img src=https://saveit-asset-bucket.s3.us-east-2.amazonaws.com/comingSoon/Profile.png alt="Saveit Profile Screen Image" width="250px" height="500px"> 
      
      <p>3. Once inside the "My Profile" screen, under "Account Settings", find the "Delete Account" button. Press it to navigate to the "Delete Account" Screen.</p>
      <img src=https://saveit-asset-bucket.s3.us-east-2.amazonaws.com/comingSoon/ProfileDeleteAccountBtn.png alt="My Profile Screen Image" width="250px" height="500px"> 
      
      <p>4. On the Delete Account screen, you have the option to permanently delete your account and provide feedback if desired. </p>
      <img src=https://saveit-asset-bucket.s3.us-east-2.amazonaws.com/comingSoon/DeleteAccount.png alt="Saveit Delete Screen Image" width="250px" height="500px"> 
      <p></p>
      <img src=https://saveit-asset-bucket.s3.us-east-2.amazonaws.com/comingSoon/AccountDeletedSuccesfully.png alt="Saveit Delete Screen Image" width="250px" height="500px"> 
     
       <p>
       Note: After deletion, if you wish to recover your account, you can log in within 20 days. However, after this 20-day period, your account will be permanently deleted and cannot be recovered.
       </p>

    </div>
`
};
