import React from 'react'
import TopBar from './termsAndConditions/components/TopBar/TopBar'
import Hero from './termsAndConditions/components/hero/Hero'
import Footer from './termsAndConditions/components/footer/Footer'

const DefaultLayout = ({children}) => {

    return (
        <>
            <TopBar/>
            <Hero/>
            {children}
            <Footer/>
        </>
    )
}

export default DefaultLayout
